import React, { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import HeadImage from '../images/Hero.png';

import Tours from './Tours';
import Welcome from './Welcome';



const Home = () => {
  return (
    <div className="App">
      <Welcome/>
      <Tours />
    </div>
  );
}

export default Home
