import 'bootstrap/dist/css/bootstrap.min.css';

import Foot from '../../images/footer.svg'; 
import './Footer.scss'

export default function Footer(props) {
    return (
        <footer className="site-footer" 
        >

          
            <div className="items">
                <img src={Foot} alt="Filipino Adventures" height="100" className="main-logo hidden sm:block" />
                <div className="text-xs sm:text-xl font-bold flex flex-col justify-center items-center sm:space-x-9">
                    <span className='whitespace-nowrap'>Email: contact@filipino-adventure.online</span>
                    <span>CALL US:+63981930765</span>
                    <span className='whitespace-nowrap hidden sm:block'>Address: Maloro,Tangub City,Misamis Occidental</span>
                    <img src={Foot} alt="Filipino Adventures " height="100" className="main-logo block sm:hidden" />
                    <div className='flex-col items-end h-full justify-end whitespace-nowrap font-thin sm:hidden'>
                      © 2023 Filipio Adventure. All rights reserved.
                  </div>
                    </div>
                  <div className='flex-col items-end h-full justify-end hidden sm:flex'>
                      © 2023 Filipio Adventure. All rights reserved.
                  </div>
            </div>
      

        </footer>
    );
}

