import React, { useState } from 'react'

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

import CartContext from '../context/CartContext';
import Calender from '../images/icons/Calendar.svg';
import ClockIcon from '../images/icons/Clock.svg';
import LanguageIcon from '../images/icons/Language.svg';
import Ok from '../images/icons/Ok.svg';
import TimeSheetIcon from '../images/icons/Timesheet.svg';
import User from '../images/icons/User.svg';

import './TourBlock.scss'


const TourBlock = (props) => {
  const [numberOfPeople, setNumberOfPeople] = useState(1)
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handlePeopleNumberChange = (e) => setNumberOfPeople(e.target.value)
  const handleSelectedDataChange = (e) => setSelectedDate(e)

  return (
    <div className={`tour-block ${props.rectangle} font-montserrat`} >

      <div className="tour-content padding-tour row ">
          <div className="col-md-6">
              <div className="text-section">

                  <h1 className='title text-position' style={{ color: 'blue' }}><b>{props.title}</b></h1>

                  <div className="img-block col-md-6 ">
                    <img src={props.image} className="img-fluid" alt={props.title}/>
                  </div>

                  <p className='description'>{props.description}</p>
                  <div className="activities">
                      <div className="activity">
                          <h3>{props.activities[0].title}</h3>
                          <p>{props.activities[0].description}</p>
                      </div>
                      <div className="activity">
                          <h3>{props.activities[1].title}</h3>
                          <p>{props.activities[1].description}</p>
                      </div>
                  </div>
                  <div className="icon-section">
                      <div className="row icons">
                          <div className="col-3">
                              <img src={ClockIcon} alt="Clock" className="icon" />
                              <p className=''><b>Starting Time:</b></p>
                              <h5 className='text-xs sm:text-lg'>{props.starting_time}</h5>
                          </div>
                          <div className="col-3">
                              <img src={LanguageIcon} alt="Language" className="icon" />
                              <p><b>Language:</b></p>
                              <h5>{props.language}</h5>
                          </div>
                          <div className="col-3">
                              <img src={TimeSheetIcon} alt="TimeSheet" className="icon" />
                              <p><b>Duration:</b></p>
                              <h5>{props.duration}</h5>
                          </div>
                          <div className="col-3">
                              <img src={Ok} alt="Ok" className="icon" />
                              <p><b>Available:</b></p>
                              <h5>{props.available}</h5>
                          </div>
                      </div>

                      <div className="price-container row">

                          <div className="book-btn col-3">
                              <p><b>{props.price}</b> php</p>
                              <button
                                className='btn-styles'
                                onClick={() => props.addToCart(props.title, selectedDate, numberOfPeople, props.price, props.image)}
                              >
                                  Book
                              </button>
                          </div>

                          <div className="col-3 w-1/2 sm:w-1/4">
                              <div className="icon-input-container flex items-center justify-center w-full">
                                  <img style={{ width: '40%' }} src={Calender} alt="Calender" className="icon" />
                                  <DatePicker
                                      className="font-bold mx-2 rounded-md text-center flex items-center justify-center h-full w-full sm:w-auto"
                                      selected={selectedDate}
                                      onChange={date => setSelectedDate(date)}
                                  />
                              </div>
                          </div>
                          <div className="col-3">
                              <div className="icon-input-container">
                                  <img style={{ width: '40%' }} src={User} alt="User" className="icon" />
                                  <input
                                      className="w-12 font-bold rounded-md text-center"
                                      type="number"
                                      value={numberOfPeople}
                                      onChange={(e) => handlePeopleNumberChange(e)}
                                      min="1"
                                  />
                              </div>
                          </div>
                      </div>
                          <div className="col-3 text-xl uppercase flex flex-col items-center justify-center w-full py-3 sm:hidden">
                              <p style={{ marginBottom: '1px' }}>
                                <b className='font-extrabold'>{props.price}</b> php
                              </p>
                              <button
                                className='p-2 px-4 font-black text-blue-500 bg-transparent border-2 border-red-500 shadow-xl active:shadow-2xl rounded-xl'
                                onClick={() => props.addToCart(props.title, selectedDate, numberOfPeople, props.price, props.image)}
                              >
                                  Book
                              </button>
                          </div>
                  </div>
              </div>
          </div>
          <div className="col-md-6 hidden sm:block">
              <img
                  src={props.image}
                  className="img-fluid"
                  alt="Cebu Adventure"
              />
          </div>
      </div>
    </div>
  )
}

export default TourBlock
