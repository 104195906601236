import React, { useState } from 'react'
import './MobileNav.scss'
import OptionsIcon from '../images/icons/Options.svg'
import { Link } from 'react-router-dom'
import CrossIcon from '../images/icons/Cross.svg'

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className='open-nav-btn' onClick={() => setIsOpen(true)}>
        <img src={OptionsIcon} alt="Navigation"/>
      </div>

      {isOpen && (
        <div className='modal-bg'>
          <div className='nav-options-container font-montserrat space-y-2'>
            <div className='nav-options'>
              <div className='close-cross'>
                <img src={CrossIcon} alt='close' onClick={() => setIsOpen(false)}/>
              </div>
              <Link aria-current="page" to="/" onClick={() => setIsOpen(false)}>Home</Link>
              <Link aria-current="page" to="/tours" onClick={() => setIsOpen(false)}>Tours</Link>
              <Link aria-current="page" to="/cart" onClick={() => setIsOpen(false)}>Cart</Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MobileNav
