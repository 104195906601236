import React, { useContext, useEffect, useState } from "react";

import { format } from 'date-fns';

import DatePicker from 'react-datepicker';

import Xendit from 'xendit-js-node';

import CartContext from "../context/CartContext";
import Calender from '../images/icons/Calendar.svg';
import Cross from '../images/icons/Cross.svg';
import User from '../images/icons/User.svg';
import Boracay from '../images/tours/img1.png';
import Palawan from '../images/tours/img2.png';
import Cebu from '../images/tours/img3.png';
import Shark from '../images/tours/img4.png';
import Choc from '../images/tours/img5.png';
import Albay from '../images/tours/img6.png';
import Scuba from '../images/tours/img7.png';
import CheckLock from '../images/icons/cart/Check Lock.svg';
import NoHiddenFees from '../images/icons/cart/No Hidden Fees.svg';
import PriceTag from '../images/icons/cart/Price Tag USD.svg';
import Protect from '../images/icons/cart/Protect.svg';

import './Cart.css';
import './Cart.scss'

import cartBackgroundImg from '../images/cart.jpg'


const Cart = () => {
  const [cart, setCart] = useContext(CartContext);

  // const [paymentFormData, setPaymenFormData] = useState({
  //   amount: 0,
  //   card_data: {
  //     account_number: null,
  //     exp_moun
  //   },
  //   card_cvn: null,
  //   is_multiple_use: null,
  //   should_authenticate: null,
  //   billing:  null,
  //   given_names: null,
  //   surname: null,
  //   email: null,
  //   mobile_number: null,
  //   phone_number: null,
  //   address: null,
  //   country: null,
  //   street_linel: null,
  //   street_: null,
  //   city: null,
  //   province_state: null,
  //   postal_code:  null,
  // })

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  //State hooks for credit card information
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [expMonth, setExpMonth] = useState('');
  const [expYear, setExpYear] = useState('');
  const [cvc, setCvc] = useState('');
  const [totalPrice, setTotalPrice] = useState('')

  const [country, setCountry] = useState('');
  const [street_line1, setStreet_line1] = useState('');
  const [street_line2, setStreet_line2] = useState('');
  const [city, setCity] = useState('');
  const [provinceState, setProvinceState] = useState('');
  const [postalCode, setPostalCode] = useState('');

  // Add new state for payment status and message
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentMessage, setPaymentMessage] = useState('');

 
const xendit = new Xendit();
Xendit.setPublishableKey(process.env.REACT_APP_XENDIT_PUBLIC_KEY3)

 function xenditResponseHandler(err, creditCardToken) {
  if (err) {
    console.error('Xendit Response Handler Error:', err);
    setPaymentStatus('failure');
    setPaymentMessage('Error in processing payment: ' + err.message);
    return;
  }

  if (creditCardToken.status === 'VERIFIED') {
    var token = creditCardToken.id;
    if (token) {
      submitPayment(token)
    }
    // Insert the token into the form so it gets submitted to the server
  } else if (creditCardToken.status === 'IN_REVIEW') {
    window.open(creditCardToken.payer_authentication_url, 'sample-inline-frame');
  } else if (creditCardToken.status === 'FAILED') {
    setPaymentStatus('failure');
    setPaymentMessage('Payment failed: ' + creditCardToken.failure_reason);
  }
}


// {
//   amount: totalPrice, 
//   card_data: {
//     account_number: cardNumber, 
//     card_exp_month: expMonth, 
//     card_exp_year: expYear, 
//   },
//   card_cvn: cvc, 
//   is_multiple_use: false,
//   should_authenticate: true,
//   billing_details:  {
//     given_names: firstName,
//     surname: lastName,
//     email: email,
//     mobile_number: phone,
//     phone_number: phone,
//   },
//   address: {
//     country: country,
//     street_linel: street_line1,
//     street_line2: street_line2,
//     city: city,
//     province_state: provinceState,
//     postal_code: postalCode,
//   },
// }, 

  // Inside your component or function
  const handlePayment = async () => {
    try {
      const token = await Xendit.card.createToken(
        {
          amount: totalPrice, 
          card_number: cardNumber, 
          card_exp_month: expMonth, 
          card_exp_year: expYear, 
          card_cvn: cvc, 
          is_multiple_use: false,
          should_authenticate: false
        }, 
        xenditResponseHandler
      );
      submitPayment(token.id); 
    } catch (error) {
      console.error('Error generating Xendit token:', error);
    }
  };

 const submitPayment = async (tokenId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment`, {
      // ... [existing fetch setup]
    });

    const result = await response.json();
    if (response.ok && result.success) {
      setPaymentStatus('success');
      setPaymentMessage('Payment Successful!');
      handleClearCart();
      resetFormFields();
    } else {
      throw new Error(result.message || 'Payment failed');
    }
  } catch (error) {
    setPaymentStatus('failure');
    setPaymentMessage(error.message || 'Payment failed');
  }
};

  // Function to reset form fields
  const resetFormFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setCardNumber('');
    setCardName('');
    setExpMonth('');
    setExpYear('');
    setCvc('');
  };

  const handleRemoveItem = (index) => {
    const newCart = cart.filter((_, i) => i !== index);
    setCart(newCart);
  };

  const handleQuantityChange = (index, newQuantity) => {
    if (newQuantity >= 1)
     {
       const newCart = cart.map((item, i) => {
         if (i === index) {
           return { ...item, people: parseInt(newQuantity, 10) };
         }
         return item;
       });
       setCart(newCart);
     } else {
      return setCart(1)
     }
  };

  const handleClearCart = () => {
    setCart([]);
  };

  const handleChangeExpiryDate = (e, tourIndex) => {
    const targetIndex = cart.findIndex((item, index) => index === tourIndex);
  
    if (targetIndex !== -1) {
      const updatedCart = [...cart]
      updatedCart[targetIndex].date = e
  
      setCart(updatedCart)
    }
  }

  useEffect(() => {
    setTotalPrice(cart.reduce(
      (total, item) => total + (item.price * item.people), 0
    ))
  }, [cart])
  
  return (
    <div className="cart-page">
    {/* Display payment status message */}
    
      <h1 style={{ color: 'blue', textAlign: 'center' }}>Complete Your Reservation</h1>

      <div className="guarantees-block">
        <div className="gurantee">
          <img src={Protect} alt=""/>
          <h1>All taxes included</h1>
        </div>
        <div className="gurantee">
          <img src={CheckLock} alt=""/>
          <h1>Secure payment</h1>
        </div>
        <div className="gurantee">
          <img src={NoHiddenFees} alt=""/>
          <h1>No credit card fees</h1>
        </div>
        <div className="gurantee">
          <img src={PriceTag} alt=""/>
          <h1>Best price</h1>
        </div>
      </div>

      {paymentStatus && (
        <div className={`payment-message w-full text-center ${paymentStatus}`}>
          {paymentMessage}
        </div>
      )}
      <div style={{ marginTop: '30px' }} className="cart-page-container">
          <>
            <div className="forms">
              <div className="personal-data font-montserrat">
                <form className="form">
                  <div className="form-label">
                    Personal Information
                  </div>
                  
                  <div className="grid-container">
                    
                    <label> First Name*
                      <input type="text" value={firstName} required={true} onChange={(e) => setFirstName(e.target.value)}/>
                    </label>

                    <label> Last Name*
                      <input type="text" value={lastName} required={true} onChange={(e) => setLastName(e.target.value)}/>
                    </label>

                    <label> Email*
                      <input type="email" value={email} required={true} onChange={(e) => setEmail(e.target.value)}/>
                    </label>
                    
                    <label> Phone*
                      <input type="tel" value={phone} required={true} onChange={(e) => setPhone(e.target.value)}/>
                    </label>
                    
                  </div>
                </form>
              </div>
              
              {/* <div className="personal-data font-montserrat">
                <form className="form">
                  <div className="form-label">
                    Personal Information
                  </div>
                  
                  <div className="grid-container">
                    
                    <label> Country*
                      <input type="text" value={country} required={true} onChange={(e) => setCountry(e.target.value)}/>
                    </label>
                    <label> City*
                      <input type="text" value={city} required={true} onChange={(e) => setCity(e.target.value)}/>
                    </label>

                    <label> Street line 1*
                      <input type="text" value={street_line1} required={true} onChange={(e) => setStreet_line1(e.target.value)}/>
                    </label>

                    <label> Street line 2*
                      <input type="email" value={street_line2} required={true} onChange={(e) => setStreet_line2(e.target.value)}/>
                    </label>
                    
                    <label> Province state*
                      <input type="tel" value={provinceState} required={true} onChange={(e) => setProvinceState(e.target.value)}/>
                    </label>
                    
                    <label> PostalCode*
                      <input type="tel" value={postalCode} required={true} onChange={(e) => setPostalCode(e.target.value)}/>
                    </label>
                    
                  </div>
                </form>
              </div> */}

                <>
                  <div className="payment-form font-montserrat">
                    <div className="form-label">
                      Payment Method
                    </div>

                    <form className="form">
                      <div className="card-fullname">
                        
                        <label> Card Number
                          <input type="text" value={cardNumber} required={true} onChange={(e) => setCardNumber(e.target.value)} />
                        </label>
                        
                        <label> Full Name
                          <input type="text" value={cardName} required={true} onChange={(e) => setCardName(e.target.value)} />
                        </label>
                
                      </div>

                      <div className="month-year-cvc">
                        <div className="grid-container">

                          <label>
                            <span>Exp Month</span>
                            <input type="text" value={expMonth} onChange={(e) => setExpMonth(e.target.value)} maxLength='2' inputMode="numeric" />
                          </label>

                          <label>
                            <span>Exp Year</span>
                            <input type="text" value={expYear} onChange={(e) => setExpYear(e.target.value)} maxLength='4'  pattern="^\d+$" inputMode="numeric" />
                          </label>

                          <label>
                            CVC
                            <input type="text" value={cvc} onChange={(e) => setCvc(e.target.value)} maxLength='3' inputMode="numeric"/>
                          </label>

                        </div>
                      </div>

                    </form>

                    <div className="payment-button-container">
                      <div className="total-price">
                        <span>Total: <b>{totalPrice}</b> PHP </span> 
                      </div>
                      <button onClick={handlePayment} type="submit" className="payment-button font-montserrat">
                        Pay Now
                      </button>
                    </div>
                  </div>
                  
                </>

                    </div>

                  </>
             


        <div className="cart">
          <div className="list">
            {cart.map((item, index) => (
              
              <>

                <div className="item" key={index}>
                  <div className="close-cross">
                    <img src={Cross} alt="cross" onClick={() => handleRemoveItem(index)}/>
                  </div>
                  <div className="img">
                    <img src={item.image} alt={item.tour}/>
                  </div>
                  <div className="title-and-parameters">
                    <div className="title">
                      <span>{item.tour}</span>
                    </div>
                    <div className="date-and-people">
                      <div className="col-3">
                            <div className="icon-input-container flex items-center justify-center">
                                <img style={{ width: '40%' }} src={Calender} alt="Calender" className="icon" />
                                <DatePicker
                                  className="datetimepicker sm:font-bold mx-2 rounded-md text-center flex items-center justify-center h-full w-full"
                                  selected={item.date}
                                  onChange={e => handleChangeExpiryDate(e, index)}
                                />
                            </div>
                        </div>
                      <div className="col-3 ml-8">
                          <div className="icon-input-container">
                              <img style={{ width: '40%' }} src={User} alt="User" className="icon" />
                              <input
                                  className="w-6 sm:w-12 sm:p-2 font-bold rounded-md text-center"
                                  type="number"
                                  value={item.people}
                                  onChange={(e) => handleQuantityChange(index, e.target.value)}
                                  min="1" // minimum number of people
                              />
                          </div>
                      </div>
                      <div className="price">
                        <span>
                          <b>{item.price * item.people}</b>
                          PHP
                        </span>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <hr/>

              
              {/* <li key={index} className="item">
                <img src={item.image} alt={item.tour} />
                <div className="item-details">
                  <h3>{item.tour}</h3>
                  <p><i className="date-icon"></i> {format(new Date(item.date), 'PPP')}</p>
                  <div>
                    <i className="people-icon"></i>
                    <input
                      type="number"
                      value={item.people}
                      onChange={(e) => handleQuantityChange(index, e.target.value)}
                      min="1"
                    />
                  </div>
                  <p><i className="price-icon"></i> {item.price} php per person</p>
                  <p>Total Price: {item.price * item.people} php</p>
                </div>
                <button className="remove-button" onClick={() => handleRemoveItem(index)}>Remove</button>
              </li> */}
              </>





            ))}

          </div>
          {cart.length > 0 && <button className="clear-btn" onClick={handleClearCart}>Clear Cart</button>}
          {cart.length === 0 && <p>Your cart is empty</p>}
        </div>
      </div>
      {/* Credit Card Information Form */}
     
    </div>
  );
};

export default Cart;
