import React from 'react'
import HeadImage from '../images/Hero.png';
import './Welcome.css'

const Welcome = () => {
  const scrollNext = () => {
    const screenHeight = window.innerHeight
    window.scrollBy({
      top: screenHeight,
      behavior: 'smooth'
    })
  }

  return (
    <div className='h-screen w-full full-screen-img-bg flex flex-col items-center justify-center font-montserrat shadow-2xl  shadow-white'>
      <div className='h-1/2 w-full sm:w-1/2 flex flex-col justify-end sm:justify-center items-end sm:items-center text-position space-y-4'>
        <div className='sm:w-3/4 sm:ml-20 '>
          <h1 className='sm:whitespace-nowrap text-5xl md:text-5xl lg:text-7xl font-semibold'>
            Filipino Adventure
          </h1>     
          <p className='font-semibold sm:font-normal md:text-lg lg:text-2xl sm:w-3/4'>
            Perfect for families, groups or couples, who prefer 
            an exclusive private experience.
          </p>  
          <div className='sm:w-3/4 text-position-r'>   
            <button 
              onClick={scrollNext}
              className='
                outline-none border-0 w-20 h-20 sm:w-24 sm:h-24 rounded-full bg-red-500 transition 
                duration-300 ease-in-out hover:bg-red-600 text-2xl sm:text-4xl shadow-xl text-white
              '
            >
              GO
            </button>
          </div>
        </div>
      </div>
      <div className='h-1/3 sm:w-3/4 flex flex-col justify-end items-center sm:items-start text-left space-y-4'>
        <div className='w-3/4 sm:ml-20'>
          <p className='sm:w-3/4 text-xs sm:text-2xl font-bold sm:font-normal'>
            Welcome to Filipino Adventure - your gateaway to the heart of
            the Philippines! We are your trusted partner in creating 
            unforgattable experiences on this tropical paraside archipelago.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Welcome
