import React, { useState, useContext } from 'react';

import DatePicker from 'react-datepicker';

import { useInView } from 'react-intersection-observer';

import 'react-datepicker/dist/react-datepicker.css';

import CartContext from '../context/CartContext';
import Calender from '../images/icons/Calendar.svg';
import ClockIcon from '../images/icons/Clock.svg';
import LanguageIcon from '../images/icons/Language.svg';
import Ok from '../images/icons/Ok.svg';
import TimeSheetIcon from '../images/icons/Timesheet.svg';
import User from '../images/icons/User.svg';
import Boracay from '../images/tours/img1.png';
import Palawan from '../images/tours/img2.png';
import Cebu from '../images/tours/img3.png';
import Shark from '../images/tours/img4.png';
import Choc from '../images/tours/img5.png';
import Albay from '../images/tours/img6.png';
import Scuba from '../images/tours/img7.png';

import './Tours.css';
import { toursData } from './ToursJsonData';
import TourBlock from './TourBlock';

function Tours() {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [numberOfPeople, setNumberOfPeople] = useState(1);
    const [cart, setCart] = useContext(CartContext);

    const [peopleCounts, setPeopleCounts] = useState([1, 1, 1, 1, 1, 1, 1]); // Assuming 7 tours

    const [ref, inView] = useInView({triggerOnce: true});

    const handlePeopleChange = (index, event) => {
        const newCounts = [...peopleCounts];
        newCounts[index] = event.target.value;
        setPeopleCounts(newCounts);
    }

    function addToCart(tour, date, people, price, imageSrc) {
        const newCartItem = {
            tour: tour,
            date: date,
            people: people,
            price: price,
            image: imageSrc  // Add this line to include the image source in the cart item
        };

        setCart([...cart, { tour, date, people, price, image: imageSrc }]);
    }


    return (
        <div className='mb-10'>
            {toursData.map((tour, index) => 
              <TourBlock key={index} {...tour} addToCart={addToCart}/>
            )}
        </div>
    );
}

export default Tours;
