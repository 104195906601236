import { Link } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import { useContext } from "react";

import CartIcon from "../component/CartIcon";
import CartContext from "../../context/CartContext";
import Logo from '../../images/footer.svg'; 

import './MainNavigation.css'; 
import MobileNav from "../../pages/MobileNav";


const MainNavigation = () => {
  const [cart, setCart] = useContext(CartContext);

  return (
    <header className="p-2 navbar-expand-lg navbar-light" 
            style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                zIndex: 1000, 
                backgroundColor: 'transparent' // Using !important to override any other styles
            }}>
      <div className="flex items-center justify-between">
        
        <Link className="navbar-brand w-1/2 flex items-center justify-center" to="/">
          <img src={Logo} alt="Filipino Adventures" height="100" className="main-logo" />
        </Link>

        {/* <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <Link className="nav-link red-button large-text" to="/cart">Book Now</Link>
        </button> */}

          <div className="hidden w-1/2 lg:flex items-center justify-start" id="navbarNav">
            <ul className="navbar-nav text-2xl font-roboto">
              <li className="nav-item">
                <Link className="nav-link blue-text-link large-text" aria-current="page" to="/">Home</Link>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link blue-text-link large-text" to="/tours">Tours</Link>
              </li>

              <li className="nav-item border-2">
                <CartIcon countInCart={cart.length}/>
              </li>

              <li className="nav-item">
                <Link className="nav-link red-button large-text p-2" to="/cart">Book Now</Link>
              </li>
              
            </ul>
          </div>

          <MobileNav/>
      </div>
    </header>
  );
}

export default MainNavigation