import React from 'react'
import { Link } from 'react-router-dom'

const CartIcon = ({countInCart}) => {
  return (
    <Link to={'/cart'} className=''>
      <div 
        className='rounded-full p-2 border-red-500 text-red-500' 
        style={{
          borderWidth:'2px',
          bordeeColor: 'lightgray',
          borderStyle: 'solid'
        }}
      >
        <svg stroke="currentColor" color='red' fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="32px" width="32px" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="21" r="1"></circle><circle cx="19" cy="21" r="1"></circle><path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path></svg>
      </div>

      <div 
        className={`relative -top-3 left-10 rounded-full flex items-center justify-center text-red-500   border-red-500 h-6 ${countInCart <= 9 && 'w-6'}`}
        style={{
          borderWidth:'2px',
          bordeeColor: 'lightgray',
          borderStyle: 'solid'
        }}  
      >
        <h1 className='text-xl m-1.5 mt-2'>{countInCart}</h1>
      </div>
    </Link>
  )
}

export default CartIcon
