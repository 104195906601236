import Boracay from '../images/tours/img1.png';
import Palawan from '../images/tours/img2.png';
import Cebu from '../images/tours/img3.png';
import Shark from '../images/tours/img4.png';
import Choc from '../images/tours/img5.png';
import Albay from '../images/tours/img6.png';
import Scuba from '../images/tours/img7.png';


export const toursData = [ 
  {
    title: "Experience Paradise at the Beaches of Boracay",
    image: Boracay,
    description: "See the best beaches of Boracay Island and explore the nearby pristine waters on this shared tour. This Boracay island hopping tour is perfect for those who want to see more of Boracay beyond the famous White Beach. This tour includes boat transfers, entrance fees to each island, the use of a snorkeling mask and a mermaid tail, a kawa hot bath, and a complimentary lunch.",
    rectangle: 'bg-rectangle-1',
    starting_time: 'at 08:00',
    language: 'English',
    duration: '4 hours',
    available: 'All year',
    activities: [
      {
        title: "Unwind",
        description: "Relax on the powdery white sands, soak up the sun, and swim in turquoise waters.",
      },
      {
        title: "Explore",
        description: "Embark on thrilling witner sports adventures or take a boal tour to nearby islands.",
      },
    ],

    price: 11000,   
  },

  {
    title: "Discover the Enchanting Beauty of Palawan",
    image: Palawan,
    description: "Explore the world-famous Puerto Princesa Underground River in Palawan by going on this day tour. This is the perfect experience for travelers who want to see this UNESCO World Heritage Site up close, as you will get to cruise down the river. Along with the use of a boat, the tour comes with the service of a guide, private land t ransfers, a set local lunch, and free pick-up and drop-off within Puerto Princesa.",
    rectangle: 'bg-rectangle-2',
    starting_time: 'at 07:00',
    language: 'English',
    duration: '6 hours',
    available: 'All year',
    activities: [
      {
        title: "Wander",
        description: "through rainforests and swim in hidden lagoons between towering limestone cliffs.",
      },
      {
        title: "Marvel",
        description: "Cruise the subterranean river, explore caves winding through mountains filled with rock formations.",
      },
    ],
    price: 27000,
  },

  {
    title: "Cebu Alegria Falls Canyoneering",
    image: Cebu,
    description: "Explore the waterfalls in the municipality of Alegria when you book this tour in Cebu. This Alegria Falls canyoneering experience includes air-conditioned van transfers, entrance fees, complimentary lunch with one round of drinks, all entrance fees, the use of safety gear and a towel, and the assistance of a guide, so all you have to do is show up and enjoy the activity!",
    rectangle: 'bg-rectangle-3',
    starting_time: 'at 08:00',
    language: 'English',
    duration: '5 hours',
    available: 'All year',
    activities: [
      {
        title: "Rappel",
        description: "Descend flowing waterfalls and traverse challenging rock formations using ropes and body strength.",
      },
      {
        title: "Trek",
        description: "Pass through lush tropical rainforests, crossing streams and swimming in crystal clear waters along jungle trails.",
      },
    ],
    price: 15000,
  },

  {
    title: "Swim with Majestic Whale Sharks in the Philippines",
    image: Shark,
    description: "See Oslob's Whale Sharks and visit Tumalog Falls in this 10-hour day tour from Cebu City. This tour comes with the use of an air-conditioned van, roundtrip boat transfers, entrance fees, the assistance of a guide, a light breakfast, and lunch, so you don't have to worry about arranging anything else for your day.",
    rectangle: 'bg-rectangle-4',
    starting_time: 'at 06:00',
    language: 'English',
    duration: '10 hours',
    available: 'All year',
    activities: [
      {
        title: "Marvel",
        description: "Snorkel and swim alongside gentle, spotted giants peacefully gliding through blue waters.",
      },
      {
        title: "Trek",
        description: "Hike forest trails to a powerful waterfall, pausing to enjoy tropical views along the way.",
      },
    ],
    price: 38000,
  },
  
  {
    title: "Discover Beauty of the Chocolate Hills",
    image: Choc,
    description: "Go on a quick adventure in Bohol w ith t his private Bohol Countryside & Chocolate Hills Adventure Park day tour. This tour is inclusive of air-conditioned transfers, all entrance fees, a free shared Loboc River Cruise lunch, and the services of a guide.",
    rectangle: 'bg-rectangle-5',
    starting_time: 'at 08:00',
    language: 'English',
    duration: '4 hours',
    available: 'All year',
    activities: [
      {
        title: "Explore",
        description: "Embark on a journey to the Chocolate Hills and witness nature's masterpiece.",
      },
      {
        title: "Book",
        description: "Don't miss out on the opportunity to experience the enchanting beauty of the Chocolate Hills.",
      },
    ],
    price: 20000,
  },

  {
    title: "Discover the Breathtaking Views of Albay",
    image: Albay,
    description: "Enjoy a day of sightseeing in tourist spots around Mayon Volcano and learn about the culture of Al bay when you book this day tour. This best views of Mayon sightseeing and culture tour is perfect for those who want to see the beauty of the iconic Mayon Volcano, as this includes stops in several viewing spots that will allow you to appreciate its perfect cone.",
    rectangle: '',
    starting_time: 'at 06:00',
    language: 'English',
    duration: '6 hours',
    available: 'All year',
    activities: [
      {
        title: "Marvel",
        description: "Gaze at the perfect cone of the active Mayon Volcano rising from vibrant green landscapes.",
      },
      {
        title: "Explore",
        description: "Visit historic Cagsawa Ruins and learn about the eruption that buried a town, leaving just a belfry.",
      },
    ],
    price: 24000,
  },
  
  {
    title: "Boracay Discover Scuba Diving",
    image: Scuba,
    description: "Explore what's beneath the surface of Boracay Island's crystal blue waters by booking this Discover Scuba Diving tour. Boracay is known for its powdery white sand beaches, and it's also one of the country's best scuba diving spots, journeyed by many from all over the globe. As the home to a thriving, biodiverse marine landscape, the experience allows you to get up and close to the residents of the sea through scuba diving without the need for a PADI license.",
    rectangle: 'bg-rectangle-6',
    starting_time: 'at 07:00',
    language: 'English',
    duration: '4 hours',
    available: 'All year',
    activities: [
      {
        title: "Dive",
        description: "Plunge into clear blue waters and discover vibrant coral reefs teeming with exotic marine life.",
      },
      {
        title: "Explore",
        description: "Get up close with sea turtles, tropical fish, sea stars, and more on your underwater adventure.",
      },
    ],
    price: 35000,
  },
]